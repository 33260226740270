import React from "react";
import "./button.scss";

const Button = ({ title, className, url, mailto }) => {
  const openLink = (url, email) => {
    if (!email) {
      window.open(url, "_blank", "noopener, noreferrer");
    } else {
      window.location.href = `mailto:${email}`;
    }
  };

  return (
      <button
        className={className}
        onClick={(e) => {
          e.preventDefault();
          openLink(url, mailto);
          console.log(e);
        }}
      >
        {title}
      </button>
  );
};

export default Button;
