import React, { useState } from "react";
import ProjectView from "./projectView";
import "./accordion.scss";

const Accordion = ({ className, Content, styleType }) => {
  const [state, setState] = useState({ id: [] });

  const handleClick = (id) => {
    if (state.id.includes(id)) {
      const arr = state.id.filter(value => value !== id)
      setState((prev) => ({ ...prev, "id": arr }))
    } else {
      const arr = [...state.id, id]
      setState({ "id": arr });
    }
  };

  const displayAccordion = Content.map((project, index) => {
    if (project.published === true) {
    return (
      <div className="li--container">
        <div className="li--title" role="button" tabIndex={index} onClick={() => handleClick(project.id)}>
          <li className="accordion" key={project.id}>
            {project.title}
          </li>
          <span className={`symbol`} key={index}>
            {state.id.includes(project.id) ? "-" : "+"}
          </span>
        </div>       
        {state.id.includes(project.id) && (
          <div className="content--container">
          <ProjectView
            key={index}
            content={project}
            styleType={styleType}
          />
          </div>)}
      </div>
    )};
  });

  return (
    <div className={`${className}--full--interact-container`}>
      <div className={`${className}--left--interact-container`}>
        <ul className={`${className}--list`}>
        {displayAccordion}
        </ul>
      </div>
      <div className="right--interact-container"></div>
    </div>
  );
};

export default Accordion;

