import React from "react";
import "./toggle.scss";

const Toggle = ({theme, changeTheme}) => {
  const handleSave = () => {
    changeTheme();
  }

  return (
    <div className="toggle-wrapper">
      { theme === "drk--mode" ? "Darke Mode" : "Light Mode" }
      <label className="toggle-label" htmlFor="toggleSwitch">
        <input
          type="checkbox"
          className="toggle-checkbox"
          name="toggleSwitch"
          id="toggleSwitch"
          onClick={() => handleSave()}
        />
        <span className="toggle-slider" />
      </label>
    </div>
  );
};

export default Toggle;
