import React from "react";
import useTheme from "../hooks/useTheme";
import useViewport from "../hooks/useViewport";
import Toggle from "../components/toggle";
import Button from "../components/button";
import InteractList from "../components/interactlist";
import Accordion from "../components/accordion";
import Projects from "../content/projects.json";
import Activities from "../content/activities.json";
import "normalize.css/normalize.css";
import "../styles/reset.scss";
import "./index.scss";

const IndexPage = () => {
  const { theme, changeTheme } = useTheme()
  const { width } = useViewport();
  const breakpoint = 999;

  const openLink = (url) => {
    window.open(url, "_blank", "noopener, noreferrer");
  };

  return (
    <div className={theme}>
      <nav>
        <div className="title-container">
          <h5>Ricky Darke</h5>
        </div>
        <div className="toggle-container">
          <Toggle theme={theme} changeTheme={changeTheme}/>
        </div>
      </nav>

      <header>
        <h1>
          Full stack developer hailing from the UK, currently living &
          tinkering in Canada.
        </h1>
      </header>

      <section className="section--about">
        <h5>About</h5>
        <p className="about-p">
          Hello - I’m Ricky, I support businesses across North America build modern, scalable web   applications. I focus on leveraging the latest technologies that allow thoughtful, accessible solutions come to life with the end user in mind.
        </p>
        <p className="about-p">
          I pair my software development skills alongside an in depth technical project management background, so the end goal is always kept top of mind. My day-to-day is filled with a healthy dose of curiosity, information gathering and problem solving alongside a glass-half-full approach.
        </p>
        <p className="about-p">
          Are you looking to kick off a new project build, or gain existing project support? Feel free to get in contact and let’s discuss making those project goals a reality.
        </p>
      </section>

      <section className="section--skills">
        <div className="skills--table">
          <h5>Area of focus</h5>
          <div className="table--columns">
            <div className="column">
              <ul>
                <li>Web Development</li>
                <li>Jamstack Development</li>
                <li>Content Modeling</li>
                <li>REST API Development</li>
                <li>Database Development</li>
              </ul>
            </div>
            <div className="column">
              <ul>
                <li>Application Development</li>
                <li>Headless CMS</li>
                <li>Headless Wordpress</li>
                <li>GraphQL Data Layers</li>
                <li>Automated Testing</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="section--work">
      <h5 className="section--work--heading">Recent work</h5>
      { width > breakpoint ? <InteractList className={"project"} Content={Projects} styleType={"project"}/> : <Accordion className={"mobileProject"} Content={Projects} styleType={"project"}/>}
      </section>

      <section className="section--fun">
        <h5 className="section--fun--heading">When I'm not coding</h5>
        { width > breakpoint ? <InteractList className={"activities"} Content={Activities} styleType={"activities"}/> : <Accordion className={"mobileActivities"} Content={Activities} styleType={"activities"}/>}
      </section>

      <div className="footer--container">
        <footer>
          <div className="row--top">
            <h2>Let's collaborate</h2>
            <Button
              title="Email me"
              className={"large--button"}
              mailto={"hello@rickydarke.dev"}
            ></Button>
          </div>
          <div className="row--middle">
            <div>
              <Button
                title="Github"
                className={"detail--button"}
                url={"https://github.com/Rdarke"}
              ></Button>
            </div>
            <div>
              <Button
                title="LinkedIn"
                className={"detail--button"}
                url={"https://www.linkedin.com/in/rickydarke/"}
              ></Button>
            </div>
          </div>
          <div className="row--bottom">
            <p className="detail-copy">Currently working remotely</p>
            <p className="detail-copy">
              Site design by <span
              role="button" 
              className="nat"
              onClick={() => openLink("https://nataliehopson.com/")}
              >Nat</span>, dev by me.
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default IndexPage;
export const Head = () => <title>Ricky Darke Development</title>;