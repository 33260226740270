import React from 'react';
import "./pill.scss";

const Pill = ({ title }) => {

  return (
    <span className="pill">
    {title}
    </span>
  )
}

export default Pill;


