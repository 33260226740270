import React from "react";
import Pill from "./pill";
import "./projectView.scss";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const ProjectView = ({ content, styleType }) => {
  const data = useStaticQuery(graphql`
    query ProjectViewQuery {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            id
            relativePath
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                transformOptions: { grayscale: true }
              )
            }
          }
        }
      }
    }
  `);

  const nodes = data.allFile.edges;
  const findNode = (arr, prop) => {
    for (let element of arr) {
      if (element.node.relativePath === prop) {
        return element.node;
      }
    }
  };

  const displaySkills = content.skills.map((skill) => {
    return <Pill title={skill} key={skill} />;
  });

  return (
    <motion.div
      className={`${styleType}--view--container`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.2 }}
    >
      <div className={`${styleType}--image--container`}>
        <GatsbyImage
          image={getImage(findNode(nodes, content.img))}
          alt={content.alt}
          loading={"eager"}
          objectFit={"contain"}
        />
      </div>
      <div className={`${styleType}--detail--container`}>
        <div className={`${styleType}--detail--description`}>
          {content.description}
        </div>
        <div className="pills--container">{displaySkills}</div>
      </div>
    </motion.div>
  );
};

export default ProjectView;
