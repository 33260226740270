import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import ProjectView from "./projectView";
import findContent from "../helpers/index";
import "./interactList.scss";

const InteractList = ({ className, Content, styleType }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [content, setContent] = useState(0);

  const handleMouseOver = (id) => {
    setIsHovering(true);
    setContent(() => findContent(Content, id));
  };

  const displayList = Content.map((project) => {
    if (project.published === true) {
    return (
      // Feature options ----- !
      // You can add "project.id === content.id ? "active" : "interact" within classNames
      // if you want project view to show constantly without mouseOut user inputs"
      // ------------------------ !
      <li
        className="interact"
        onMouseOver={() => handleMouseOver(project.id)}
        onFocus={() => handleMouseOver(project.id)}
        onMouseOut={() => setIsHovering(false)}
        onBlur={() => setIsHovering(false)}
        key={project.id}
      >
        {project.title}
      </li>
    )};
  });

  return (
    <div className={`${className}--full--interact-container`}>
      <div className={`${className}--left--interact-container`}>
        <ul className={`${className}--list`}>
          {displayList}
        </ul>
      </div>
      <div className={`${className}--right--interact-container`}>
        <AnimatePresence>
          {isHovering && (
            <ProjectView content={content} styleType={styleType} />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default InteractList;