import { useState } from 'react';

const lightTheme = "light--mode";
const darkTheme = "drk--mode";

export default function useTheme() {
  const isBrowser = typeof window !== 'undefined'
  const [theme, setTheme] = useState(isBrowser ? window.localStorage.getItem("theme") || darkTheme : darkTheme);
  
  const changeTheme = () => {
    const updateStorage = (val) => window.localStorage.setItem('theme', val)
    if (theme === darkTheme) {
      setTheme(lightTheme)
      updateStorage(lightTheme)
    } else {
      setTheme(darkTheme)
      updateStorage(darkTheme)
    }
  };

  return {theme, changeTheme};
};